import './App.css';
import blob from './blob.gif';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={blob} className="App-blob" alt="blob" />
      </header>
    </div>
  );
}

export default App;
